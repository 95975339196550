<template lang="pug">
  payments-provider(ref="payments" @canceleed="updateToPaid")
    template(v-slot:default="props")
      span
        payments-cancel-provider(
          :ID="props.ID"
          :payments="props.logs"
          @end="props.create"
          @canceled="props.updateCanceledLog"
        )
          template(v-slot:default="{showing, cancelPayment, send, skip, toggle, showWhenHasCreatedPayment, processing}")
            not-recomended-payment-validate(:formErrors="props.formErrors" @valid="showWhenHasCreatedPayment")
              template(v-slot:default="notRecomendedPayment")
                span
                  v-dialog(:value="props.showing" :max-width="1000" scrollable @input="props.toggle")
                    payments(
                      v-if="props.showing"
                      :items="props.list"
                      :itemsLoading="props.listLoading"
                      :formErrors="props.formErrors"
                      :editorData="props.editorData"
                      :processing="props.processing"
                      :log="props.logs"
                      :recomendedPayment="props.recomendedPayment"
                      @click:custom="props.setNewEditor"
                      @close="props.toggle"
                      @submit="showWhenHasCreatedPayment"
                      @submit:notRecomendedPayment="notRecomendedPayment.validate"
                    )
                      template(v-slot:log-actions="{ paymentLog, isPaid }")
                        refund-create(@refunded="props.updateCandidateRefunds")
                          template(v-slot:default="refund")
                            v-menu(:value="refund.showing" @input="refund.updateShowing" :close-on-content-click="false"
                              offset-x offset-y
                              v-if="currentPerms.has(PERM.REFUND)" )
                              template(v-slot:activator="{on}")
                                v-btn(small icon color="error" :loading="processing" v-on="on")
                                  v-icon mdi-cash
                              v-card
                                v-card-text
                                  v-text-field(:value="refund.formData.amount" @input="refund.updateFormData('amount', $event)" label="Amount")
                                  v-btn(
                                    color="primary"
                                    @click="refund.validateBeforeCreate(paymentLog.ID, refund.formData, props.candidate)"
                                  ) save
                        v-btn(
                          :disabled="isPaid"
                          small icon color="error" :loading="processing" @click="cancelPayment(paymentLog.ID)"
                        )
                          v-icon mdi-delete

                  confirmation-dialog(
                    v-model="showing"
                    @okBtnClicked="skip"
                    @failBtnClicked="toggle"
                    title="Candidate has payment"
                    descr="Are you sure you want to create new payment and cancel the old one?"
                    okText="ok"
                    failText="cancel"
                  )
</template>

<script>
import {state} from "@/app/admin/Admin-layout.vue"
import { CRM_PERMS } from '@/util/const'

export default {
  props: {
    list: Array
  },

  data: () => ({
    state: state,
    PERM: CRM_PERMS
  }),

  computed: {
    currentPerms() {
      if(!this.state.perms) return {
        has: () => false
      }
      return this.state.perms
    },
  },
  methods: {
    paymentsOpen(data) {
      this.$refs.payments.saveCandidateAndOpen(data)
    },

    updateToPaid({ID}) {
      if(!this.list) return
      let item = this.list.find(item => item.ID === ID)
      if(!item) return
      item.is_course_paid = true
    }
  },
  components: {
    PaymentsProvider: () => import('./CanidatesPaymentsProvider.vue'),
    Payments: () => import('./CandidatesPayments.vue'),
    paymentsCancelProvider: () => import('./CandidatesPaymentCancelProvider.vue'),
    refundCreate: () => import('./CandidatesRefundCreate.vue'),
    notRecomendedPaymentValidate: () => import('./CandidatesNotRecomendedPaymentsValidate.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>