var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('payments-provider',{ref:"payments",on:{"canceleed":_vm.updateToPaid},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_c('payments-cancel-provider',{attrs:{"ID":props.ID,"payments":props.logs},on:{"end":props.create,"canceled":props.updateCanceledLog},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var showing = ref.showing;
var cancelPayment = ref.cancelPayment;
var send = ref.send;
var skip = ref.skip;
var toggle = ref.toggle;
var showWhenHasCreatedPayment = ref.showWhenHasCreatedPayment;
var processing = ref.processing;
return [_c('not-recomended-payment-validate',{attrs:{"formErrors":props.formErrors},on:{"valid":showWhenHasCreatedPayment},scopedSlots:_vm._u([{key:"default",fn:function(notRecomendedPayment){return [_c('span',[_c('v-dialog',{attrs:{"value":props.showing,"max-width":1000,"scrollable":""},on:{"input":props.toggle}},[(props.showing)?_c('payments',{attrs:{"items":props.list,"itemsLoading":props.listLoading,"formErrors":props.formErrors,"editorData":props.editorData,"processing":props.processing,"log":props.logs,"recomendedPayment":props.recomendedPayment},on:{"click:custom":props.setNewEditor,"close":props.toggle,"submit":showWhenHasCreatedPayment,"submit:notRecomendedPayment":notRecomendedPayment.validate},scopedSlots:_vm._u([{key:"log-actions",fn:function(ref){
var paymentLog = ref.paymentLog;
var isPaid = ref.isPaid;
return [_c('refund-create',{on:{"refunded":props.updateCandidateRefunds},scopedSlots:_vm._u([{key:"default",fn:function(refund){return [(_vm.currentPerms.has(_vm.PERM.REFUND))?_c('v-menu',{attrs:{"value":refund.showing,"close-on-content-click":false,"offset-x":"","offset-y":""},on:{"input":refund.updateShowing},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"error","loading":processing}},on),[_c('v-icon',[_vm._v("mdi-cash")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('v-text-field',{attrs:{"value":refund.formData.amount,"label":"Amount"},on:{"input":function($event){return refund.updateFormData('amount', $event)}}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return refund.validateBeforeCreate(paymentLog.ID, refund.formData, props.candidate)}}},[_vm._v("save")])],1)],1)],1):_vm._e()]}}],null,true)}),_c('v-btn',{attrs:{"disabled":isPaid,"small":"","icon":"","color":"error","loading":processing},on:{"click":function($event){return cancelPayment(paymentLog.ID)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)}):_vm._e()],1),_c('confirmation-dialog',{attrs:{"title":"Candidate has payment","descr":"Are you sure you want to create new payment and cancel the old one?","okText":"ok","failText":"cancel"},on:{"okBtnClicked":skip,"failBtnClicked":toggle},model:{value:(showing),callback:function ($$v) {showing=$$v},expression:"showing"}})],1)]}}],null,true)})]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }